<template>
  <div
    v-editable="blok"
    class="PromoGrid"
    :class="desktopWidth('full')"
  >
    <div
      v-if="blok.headingTitle || blok.headingButtonText"
      class="flex flex-row flex-wrap justify-between items-center gap-8 mb-16"
      :class="{'flex-col text-center' : blok.headingAlign === 'center'}"
    >
      <div class="type-headline-lg desk:type-headline-2xl"> {{ blok.headingTitle }}</div>

      <Nuxt-link
        :to="$sbLinkFix(blok.headingButtonLink)"
        :target="$sbLinkTarget(blok.headingButtonLink)"
      >
        <div
          v-if="blok.headingButtonText"
          class="btn btn--primary"
          :class="{
            'btn--secondary': blok.headingButtonStyle === 'secondary',
            'btn--ghost': blok.headingButtonStyle === 'ghost',
            'btn--text': blok.headingButtonStyle === 'plain',
          }"
        >
          {{ blok.headingButtonText }}
        </div>
      </Nuxt-link>
    </div>
    <div
      v-if="(blok.mobileSlider && isMobile) || (featureCount > 4)"
      class="relative"
    >
      <client-only>
        <Swiper
          class="swiper-cover desk:!overflow-hidden !overflow-visible w-full desk:!pb-40 !pb-32"
          :modules="[SwiperNavigation, SwiperPagination, SwiperA11y]"
          :space-between="uiStore.isMobile ? 8 : 12"
          :pagination="{
            clickable: true,
            dynamicBullets: true,
          }"
          :navigation="{
            nextEl: `.swiper-button-next.swiper-for-blok-${blok._uid}`,
            prevEl: `.swiper-button-prev.swiper-for-blok-${blok._uid}`,
          }"

          :loop="false"
          :slides-per-view="uiStore.isMobile ? 1.1 : 4"
          :no-swiping="true"
        >
          <SwiperSlide
            v-for="(feature, index) in blok.content"
            :key="feature._uid"
          >
            <component
              :is="feature.component"
              :key="feature._uid"
              :aspect-ratio-mobile="blok.aspectRatioMobile"
              :aspect-ratio-desktop="blok.aspectRatioDesktop"
              :blok="feature"
              :placement="placement"
              :size="promoSize(index+1)"
              :inside-grid="true"
              class="mb-24 desk:mb-32"
              :class="`promo${index+1}`"
            />
          </SwiperSlide>
        </Swiper>
      </client-only>

      <div class="hidden desk:block">
        <div
          class="swiper-button-prev !top-[158px]"
          :class="{[`swiper-for-blok-${blok._uid}`]: true}"
        />
        <div
          class="swiper-button-next !top-[158px]"
          :class="{[`swiper-for-blok-${blok._uid}`]: true}"
        />
      </div>
    </div>

    <div v-else class="flex flex-col tabletPortraitOnly:flex-row tabletPortraitOnly:gap-12 desk:flex-row gap-32 desk:gap-12">
      <component
        :is="promo.component"
        v-for="(promo, index) in blok.content"
        :key="promo._uid"
        :aspect-ratio-mobile="blok.aspectRatioMobile"
        :aspect-ratio-desktop="blok.aspectRatioDesktop"
        :blok="promo"
        :placement="placement"
        :size="promoSize(index+1)"
        :inside-grid="true"
        class="mobOnly:mb-24"
        :class="`promo${index+1}`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">

import { PromoBlock, StoryBlokLink, StoryBlokPropBase } from '~/constants/types/storyblok';
import useDesktopWidth from '~/composeables/useDesktopWidth';
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';

import { AspectRatioKeys } from '~/constants/aspectRatioKeys';

const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);

type Props = StoryBlokPropBase & {
  content: PromoBlock[];
  mobileSlider: boolean;
  aspectRatioMobile: AspectRatioKeys;
  aspectRatioDesktop: AspectRatioKeys;
  // Heading fields
  headingTitle: string;
  headingButtonText: string;
  headingButtonLink: StoryBlokLink;
  headingButtonStyle: 'primary' | 'secondary' | 'ghost' | 'plain';
  headingAlign: 'left' | 'center';
}

const props = defineProps<{
  blok: Props,
  placement: number,
}>();
const { desktopWidth } = useDesktopWidth(props);

const featureCount = computed(() => props.blok.content.length);

const promoSize = () => {
  if (props.blok.content.length === 1) {
    return 'full';
  }
  if (props.blok.content.length === 2) {
    return 'half';
  }
  if (props.blok.content.length === 3) {
    return 'third';
  }
  return 'fourth';
};

</script>

<style scoped lang="postcss">
.swiper-button-next {
  @apply !-right-16;
}
.swiper-button-prev {
  @apply !-left-16;
}
</style>
